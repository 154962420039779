import React from "react";
import styled from "styled-components";
import Nav from "./Nav";
import Footer from "./Footer";
import "normalize.css";
import "../global.css";
import { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`

  body {
      background: linear-gradient(rgba(250,250,250,.2), rgba(250,250,250,.2)), url(${(props) => (props.background ? props.background : "white")})
      no-repeat center center fixed;
    background-size: cover;
    background-blend-mode: color-dodge;
    background-position: 100% 0;
  }
`;

const Container = styled.div`
  width: 70%;
  height: auto;
  display: flex;
  justify-content: start;
  align-items: center;
  flex-direction: column;
  margin: 30px auto 20px;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;

  @media (max-width: 650px) {
    width: 90%;
    margin: 10px auto;
  }
`;

const DivLayout = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  text-align: center;
  color: var(--primary-color);
  margin: 20px 0 30px;
  div {
    margin: 10px;
    h2 {
      /* margin: 20px; */
    }
  }
`;

export default function Layout({ background, children }) {
  return (
    <>
      <GlobalStyle background={background} />;
      <Nav />
      <Container>
        <DivLayout>{children}</DivLayout>
      </Container>
      <Footer />
    </>
  );
}
