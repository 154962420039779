import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import useWindowDimensions from "../hooks/useWindowDimensions";

const NavContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 180px;
  a {
    text-decoration: none;
  }
  @media (max-width: 650px) {
    flex-direction: column;
  }
`;

const NavLinks = styled.ul`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  list-style-type: none;
  li {
    font-size: 1.5em;
    margin-right: 60px;
    a {
      font-size: 1em;
      font-family: "Quicksand", "Helvetica Neue", "Helvetica";
      font-weight: 700;
      color: #ffffff;
      text-decoration: none;
      text-shadow: 1px 1px 1px rgb(0 100 100 / 75%);
      transition: 0.2s;
      &:hover {
        text-shadow: 2px 2px 1px rgb(0 100 100 / 75%);
      }
    }
  }
`;
const NavTitle = styled.h1`
  font-family: "Dancing Script", cursive;
  line-height: 1.2;
  font-size: 4em;
  color: #ffffff;
  text-shadow: 0 4px 2px rgb(0 100 100 / 75%);
  margin: 0;
  transform: rotate(-25deg);

  @media (max-width: 650px) {
    margin-right: 10px;
    display: inline;
    transform: rotate(0deg);
  }
`;

const Nav = () => {
  const { width } = useWindowDimensions();

  return width > 650 ? (
    <NavContainer>
      <NavLinks>
        <li>
          <Link to="/">Home</Link>
        </li>
        {/* <li>
          <Link to="/about">About</Link>
        </li> */}
      </NavLinks>
      <Link to="/">
        <NavTitle>Blue</NavTitle>
        <NavTitle>Spivey</NavTitle>
      </Link>
      <NavLinks>
        {/* <li>
          <Link to="/contact">Contact</Link>
        </li> */}
        <li>
          <Link to="/wedding">Wedding</Link>
        </li>
      </NavLinks>
    </NavContainer>
  ) : (
    <NavContainer>
      <Link to="/">
        <NavTitle>Blue</NavTitle>
        <NavTitle>Spivey</NavTitle>
      </Link>

      <NavLinks>
        {/* <li>
        <Link to="/contact">Contact</Link>
      </li> */}
        <li>
          <Link to="/wedding">Wedding</Link>
        </li>
      </NavLinks>
    </NavContainer>
  );
};

export default Nav;
