import React from "react";
import styled from "styled-components";

const StyledFooter = styled.div`
  grid-row-start: 2;
  grid-row-end: 3;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  p {
    font-size: 18px;
    color: #ffffff;
  }
`;

const Footer = () => {
  return (
    <StyledFooter>
      <p>&copy; {new Date().getFullYear()} bluespivey.com</p>
    </StyledFooter>
  );
};

export default Footer;
